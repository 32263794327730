import React, { useState, ReactNode, useContext } from 'react'
import { Grid, Paper, Typography, styledComponent, Box, VideocamIcon, Link, Edit, Button } from '@hermes/web-components'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../providers/AppData'
import { Marker } from '../../../types/map'
import { SpecialistProfile } from '../../../types/specialist'

import { MapState } from '../../../types'
import PracticeLocationCard from './PracticeLocationCard'
import { removeVideoConsultation } from '../../../utils/address'
import { doctifyProviderDomain, endpoint, pathMap } from '../../../constants/profile'
import { handleAddCookieCTA } from '../../../utils/cookie'

const ProfileMapModal = dynamic(() => import('../../profiles/ProfileMapModal'), { ssr: false })

const LocationTypeSelectorRoot = styledComponent(Grid, {
  shouldForwardProp: (prop: string) => !['active'].includes(prop)
})<{ active?: boolean }>(({ theme }) => ({
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: theme.palette.primary.main,
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.palette.common.white
    }
  }
}))

type SpecialistWorkingHoursProps = Pick<
  SpecialistProfile,
  'practices' | 'videoConsultation' | 'staticMapImage' | 'basic' | 'plan' | 'slug'
> & {
  id: string | number
  onGetInTouch: () => void
  onBookAppointment: (button?: string, interactionLabel?: string) => void
  isUnclaimed?: boolean
  hideBookAppointment?: boolean
}

type LocationTypeSelectorProps = {
  title: string | ReactNode
}

function LocationTypeSelector({ title }: LocationTypeSelectorProps) {
  return (
    <>
      <LocationTypeSelectorRoot container active={true}>
        {typeof title === 'string' ? (
          <Typography variant="body2" fontWeight="inherit">
            {title}
          </Typography>
        ) : (
          title
        )}
      </LocationTypeSelectorRoot>
    </>
  )
}

function SpecialistWorkingHours({
  practices,
  staticMapImage,
  basic,
  id,
  slug,
  plan,
  isUnclaimed = false,
  onBookAppointment,
  hideBookAppointment
}: SpecialistWorkingHoursProps) {
  const { locale } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const router = useRouter()
  const [activeLocation, setAtiveLocation] = useState<string | number>('all')
  const emptyMarker = { position: { lat: 0, lon: 0 } }
  const [mapState, setMapState] = useState<MapState>({ isOpen: false, marker: emptyMarker })
  const toggleMapModal = (marker: Marker = emptyMarker) =>
    setMapState((prev: MapState) => ({ ...prev, isOpen: !prev.isOpen, marker }))

  const isMarketingEmail = router.query?.utm_source === 'marketingemail'
  const filteredPractices = practices.filter(removeVideoConsultation)

  const videoConsultationPractices = practices.filter((practice) => practice.slug === 'video-consultation')
  const entryTierLink = isMarketingEmail
    ? `/${locale}${router.asPath.split('?')[0]}/${
        locale === 'de' || locale === 'de-at' ? 'beanspruchen-sie-ihr-profil' : 'claim-your-profile'
      }`
    : doctifyProviderDomain + pathMap[locale] + endpoint

  const VideoConstultationSelector = (
    <Box display="flex" alignItems="center">
      <VideocamIcon fontSize="small" color="inherit" sx={{ mr: 1 }} />
      <Typography variant="body2" color="text.disabled">
        {translate('video_consultation')}
      </Typography>
    </Box>
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h2" component="h2" color="common.white">
          {translate('locations')}
        </Typography>
        {isUnclaimed && basic && (
          <Link
            href={entryTierLink}
            underline="none"
            display="flex"
            justifyContent="space-between"
            color="common.white"
            alignItems="center"
            onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
          >
            <Typography color="inherit" variant="body2" mr={1}>
              {translate(isMarketingEmail ? 'claim-your-profile' : 'manage_your_profile')}
            </Typography>
            <Edit color="inherit" fontSize="inherit" />
          </Link>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item>
          <Paper sx={{ height: '100%', boxShadow: 'none', borderRadius: '16px', overflow: 'hidden' }}>
            <LocationTypeSelector title={translate('profile.all_locations')} />
            {filteredPractices.map((practice) => (
              <PracticeLocationCard
                key={`specialist-practice-${practice.id}`}
                previewImage={staticMapImage}
                onSelect={setAtiveLocation}
                activeLocation={activeLocation}
                openMap={toggleMapModal}
                hideBookAppointment={hideBookAppointment}
                {...practice}
                onGetInTouch={() => onBookAppointment('all', 'enquire')}
              />
            ))}
            {!!videoConsultationPractices.length && (
              <>
                <LocationTypeSelector title={VideoConstultationSelector} />
                {videoConsultationPractices.map((practice) => (
                  <PracticeLocationCard
                    key={`specialist-practice-video-${practice.id}`}
                    previewImage={staticMapImage}
                    onSelect={setAtiveLocation}
                    activeLocation={activeLocation}
                    openMap={toggleMapModal}
                    hideBookAppointment={hideBookAppointment}
                    {...practice}
                    onGetInTouch={() => onBookAppointment('all', 'enquire')}
                  />
                ))}
              </>
            )}
            {!hideBookAppointment && (
              <Button
                variant="contained"
                sx={{
                  m: '11px 0 11px 20px',
                  p: '6px 16px',
                  borderRadius: '32px',
                  fontSize: '11px',
                  backgroundColor: '#406AE3'
                }}
                onClick={() => onBookAppointment('book', 'book appointment')}
              >
                {translate('book_appointment')}
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
      {mapState.isOpen && <ProfileMapModal mapState={mapState} onClose={toggleMapModal} />}
    </Box>
  )
}

export default SpecialistWorkingHours
